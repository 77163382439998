var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../redux/store";
import { EventsFilterForMobile } from "../../EventsFilter/EventsFilterForMobile";
import { ModalUserUpdate } from "../modalUserUpdate/ModalUserUpdate";
import { ModalChangeAvatar } from "../modalDeleteOrChangeAvatar/modalDeleteOrChangeAvatar";
import { ModalCreatePostMain } from "../modalCreatePostMain/ModalCreatePostMain";
import ModalErrorAlert from "../modalErrorAlert/ModalErrorAlert";
import { ModalCreatePostMini } from "../modalCreatePostMini/modalCreatePostMini";
import { ModalEditingUserMobile } from "../modalEditingUserMobile/ModalEditingUserMobile";
import { ModalResetPassword } from "../modalResetPassword/modalResetPassword";
import ModalSuccessAlert from "../modalSuccessAlert/ModalSuccessAlert";
import { ModalOpenSubscribesCard } from "../modalOpenSubscribesCard/ModalOpenSubscribesCard";
import { ModalFilterAndSearch } from "../modalFilterAndSearch/modalFilterAndSearch";
import { ModalInCompanySearchCompanieLittleBlock } from "../modalInCompanySearchCompanieLittleBlock/modalInCompanySearchCompanieLittleBlock";
import { ModalChangeCoverNew } from "../modalChangeCover/ModalChangeCoverNew";
import { ModalSettingsAndUpdateUser } from "../modalSettingAndUpdateUser/ModalSettingsAndUpdateUser";
import ModalErrorImgSize from "../modalErrorImgSize/ModalErrorImgSize";
import { PopupWithOverlay } from "../../../UIKit/PopupWithOverlay/Popup";
import { setActive } from "../../../redux/modalReducer";
import { PopupWithoutOverlay } from "../../../UIKit/PopupWithoutOverlay/PopupWithoutOverlay";
import { ModalMobileFilterForCompanies } from "../modalMobileFilterForCompanies/ModalMobileFilterForCompanies";
export var ModalContainer = function () {
    var dispatch = useAppDispatch();
    var isActive = useSelector(function (state) { return state.modal.isActive; });
    var modal_name = useSelector(function (state) { return state.modal.modal_name; });
    var ErrorMessageToUserForModal = useSelector(function (state) { return state.modal.ErrorToUserForModal; });
    var MessageToUserForModal = useSelector(function (state) { return state.modal.MessageToUserForModal; });
    var handleCloseModal = function () {
        dispatch(setActive(false));
    };
    var modal = null;
    var modalsWithoutOverlay = ["events-filter", "filter-search-modal", "mobile-filter-for-companies-modal", "create-post-mini-modal"];
    switch (modal_name) {
        case "events-filter":
            modal = _jsx(EventsFilterForMobile, { isActive: isActive }, modal_name);
            break;
        case "editing-modal":
            modal = _jsx(ModalUserUpdate, { isActive: isActive }, modal_name);
            break;
        case "user-update-and-configure-modal":
            modal = _jsx(ModalSettingsAndUpdateUser, { onClose: handleCloseModal });
            break;
        case "delete/change-avatar-modal":
            modal = _jsx(ModalChangeAvatar, { isActive: isActive }, modal_name);
            break;
        case "create-post-main":
            modal = _jsx(ModalCreatePostMain, { isActive: isActive }, modal_name);
            break;
        case "create-post-mini-modal":
            modal = _jsx(ModalCreatePostMini, { isActive: isActive }, modal_name);
            break;
        case "change-cover-modal":
            modal = _jsx(ModalChangeCoverNew, { isActive: isActive }, modal_name);
            break;
        case "error-alert-modal":
            modal = _jsx(ModalErrorAlert, { MessageToUser: MessageToUserForModal, isActive: isActive, Error: ErrorMessageToUserForModal }, modal_name);
            break;
        case "error-img-size":
            modal = _jsx(ModalErrorImgSize, { MessageToUser: MessageToUserForModal, isActive: isActive, Error: ErrorMessageToUserForModal }, modal_name);
            break;
        case "success-alert-modal":
            modal = _jsx(ModalSuccessAlert, { MessageToUser: MessageToUserForModal, isActive: isActive });
            break;
        case "edit-user-mobile":
            modal = _jsx(ModalEditingUserMobile, { isActive: isActive }, modal_name);
            break;
        case "refresh-password-modal":
            modal = _jsx(ModalResetPassword, { isActive: isActive, type: modal_name });
            break;
        case "refresh-password-use-email":
            modal = _jsx(ModalResetPassword, { isActive: isActive, type: modal_name });
            break;
        case "subscribers-card":
            modal = _jsx(ModalOpenSubscribesCard, { isActive: isActive });
            break;
        case "filter-search-modal":
            modal = _jsx(ModalFilterAndSearch, { isActive: isActive });
            break;
        case "mobile-filter-for-companies-modal":
            modal = _jsx(ModalMobileFilterForCompanies, { isActive: isActive });
            break;
        case "reset-state-Modal":
            modal = null;
            break;
        case "page-company-search-companie-little-block-data":
            modal = _jsx(ModalInCompanySearchCompanieLittleBlock, { isActive: isActive });
            break;
        default:
            break;
    }
    if (modalsWithoutOverlay.includes(modal_name)) {
        return (_jsx(_Fragment, { children: _jsx(PopupWithoutOverlay, __assign({ onClose: handleCloseModal, isOpened: isActive }, { children: modal })) }));
    }
    else {
        return (_jsx(_Fragment, { children: _jsx(PopupWithOverlay, __assign({ onClose: handleCloseModal, isOpened: isActive }, { children: modal })) }));
    }
};
